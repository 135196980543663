import React, { createContext } from "react"
import useToggleStateGetSet from "../hooks/useToggleStateGetSet"
// import useToggleState from "../hooks/useToggleState"
////

export const ThemeContext = createContext()

export function ThemeContextProvider(props) {
  const [isDarkMode, toggleTheme] = useToggleStateGetSet("isDark")

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {props.children}
    </ThemeContext.Provider>
  )
}
