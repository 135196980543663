import Brightness2Icon from "@material-ui/icons/Brightness2"
import WbSunnyIcon from "@material-ui/icons/WbSunny"
import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useContext } from "react"
import { FaBars, FaTimes } from "react-icons/fa"
import { IconContext } from "react-icons/lib"
import { ThemeContext } from "../../contexts/ThemeContext"
import { Button, buttonBckg } from "../../globalStyles"
import useWindowSize from "../../hooks/resizeHook"
import useToggleState from "../../hooks/useToggleState"
import {
  DarkMode,
  Logo,
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavItemBtn,
  NavLinks,
  NavLogo,
  NavMenu,
} from "./NavbarStyle"

// import logi from "../../../design_raw/logo-new.svg"

const Navbar = props => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext)
  const [mobileNavClick, toggleMobile] = useToggleState()
  const [width] = useWindowSize()

  const data = useStaticQuery(graphql`
    {
      allContentfulNavbar {
        nodes {
          nav2
          nav3
          nav4
          nav5
          nav1
          logo {
            file {
              url
            }
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
            title
          }
        }
      }
    }
  `)

  const {
    nav2,
    nav3,
    nav4,
    nav5,
    // nav1,
    logo,
  } = data.allContentfulNavbar.nodes[0]
  const activeStyle = {
    transform: "scale(1.1)",
  }
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav dark={isDarkMode}>
          <NavbarContainer>
            <NavLogo
              bg={isDarkMode ? buttonBckg.dark : buttonBckg.light}
              to="/"
            >
              {/* <Logo src={logo.file.url} alt={logo.title} /> */}
              <Logo fluid={logo.fluid} alt={logo.title} />
              {/* <Logo src={logi} alt={logo.title} /> */}
            </NavLogo>
            <MobileIcon className="my-auto" onClick={toggleMobile}>
              {mobileNavClick ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            {width <= 960 && (
              <>
                <div
                  className=" my-auto ml-5 mr-4"
                  style={{ listStyle: "none" }}
                >
                  {isDarkMode ? (
                    <WbSunnyIcon
                      style={{ listStyle: "none" }}
                      onClick={toggleTheme}
                    />
                  ) : (
                    <Brightness2Icon
                      style={{ listStyle: "none" }}
                      onClick={toggleTheme}
                    />
                  )}
                </div>
              </>
            )}
            <NavMenu click={mobileNavClick}>
              {/* <NavItem>
                 <NavLinks
                  activeClassName="active"
                  activeStyle={{ transform: "scale(1.1)" }}
                  to="/"
                >
                  {nav1}
                </NavLinks> 
              </NavItem> */}

              <NavItem>
                <NavLinks
                  activeClassName="active"
                  activeStyle={activeStyle}
                  to="/about"
                >
                  {nav2}
                </NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks
                  activeClassName="active"
                  activeStyle={activeStyle}
                  to="/services"
                >
                  {nav3}
                </NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks
                  activeClassName="active"
                  activeStyle={activeStyle}
                  to="/works"
                >
                  {nav4}
                </NavLinks>
              </NavItem>

              <NavItemBtn>
                <Link
                  activeClassName="active"
                  activeStyle={activeStyle}
                  to="/contact"
                >
                  <Button dark={isDarkMode}>{nav5}</Button>
                </Link>
              </NavItemBtn>

              {width > 960 && (
                <>
                  <NavItem className="" style={{ marginTop: "26px" }}>
                    <DarkMode
                      name="dark"
                      className="m-auto"
                      size="small"
                      checked={isDarkMode}
                      onChange={toggleTheme}
                    />
                  </NavItem>
                  <NavItem style={{ marginTop: "28px" }}>
                    <label htmlFor="dark">
                      {isDarkMode ? <WbSunnyIcon /> : <Brightness2Icon />}
                    </label>
                  </NavItem>
                </>
              )}
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  )
}

export default Navbar
