import { useState } from "react"
// import saveToLocalStorage from "./saveToLocalStorage"

function useToggleState(initialVal = false) {
  const [state, setState] = useState(initialVal)

  const toggle = () => {
    setState(!state)
  }

  return [state, toggle]
}
export default useToggleState
