import Switch from "@material-ui/core/Switch"
import AcUnitIcon from "@material-ui/icons/AcUnit"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Container } from "../../globalStyles"
const buttonBckg = {
  light: "rgba(85, 89, 257)",
  lightHover: "rgb(85, 82, 247)",
  dark: "rgb(41, 125, 245)",
  darkHover: "rgb(21, 113, 245)",
}

let navHeight = "50px"
let navHeightSm = "50px"

export const Nav = styled.nav`
  background: ${({ dark }) => (!dark ? buttonBckg.light : buttonBckg.dark)};
  height: ${navHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  position: sticky;
  top: 0px;
  z-index: 999;
  transition: all 300ms;
  opacity: 0.9;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.45);

  @media screen and (max-width: 960px) {
    height: ${navHeightSm};
  }
`

export const NavbarContainer = styled(Container)`
  display: flex;

  justify-content: space-between;
  height: ${navHeight};
  top: 0;

  @media screen and (max-width: 960px) {
    height: ${navHeightSm};
  }
  ${Container}
`

export const NavLogo = styled(Link)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
`

export const NavIcon = styled(AcUnitIcon)`
  margin-right: 0.5rem;
`

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;

    right: 0;
    transition: all 500ms;
    top: 13px;
    transform: translate(-70%);
    * {
      font-size: 20px;
    }
    cursor: pointer;
    &:active {
      opacity: 0;
    }
  }
`
export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  overflow: hidden;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: ${navHeightSm};
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 500ms ease;
    background: #101522;

    padding: 0;
    margin: 0;
  }
`
export const NavItem = styled.li`
  height: ${navHeight};

  /* &:after {
    transition: all 300ms;

    border-radius: 5px;
    background: ${buttonBckg.light};
    content: "";
    margin-top: -10px;
    display: block;
    height: 3px;
    width: 0%;
  } */

  &:hover {
    transition: all 300ms;

    /* &:after {
      width: 100%;
    } */
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    height: ${navHeightSm};
    &:hover {
      border: none;
      transition: all 300ms;
    }
  } ;
`

export const NavLinks = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0 1rem 0 1rem;
  height: 100%;
  transition: all 300ms;
  border-radius: 4px;

  &:hover {
    /* transform: scale(1.1); */
  }

  @media screen and (max-width: 960px) {
    margin: 0 auto 0 auto;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    text-align: center;
    padding: 2rem;
    display: table;
    &:hover {
      color: #4b59f7;
      transition: all 300ms ease;
    }
  }
`

export const NavItemBtn = styled.li`
  margin: 0 0.5rem 0 0.5rem !important;
  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
`

export const NavBtnLink = styled.a`
  align-items: center;
  justify-content: center;
  display: flex;
  text-decoration: none;
  padding: 4px 10px;

  height: 100%;
  width: 100%;
  border: none;
  transition: all 300ms;
`
export const DarkMode = styled(Switch)`
  align-items: center;
  justify-content: center;
`

// export const Logo = styled.img`
//   width: 140px;
//   /* margin-left: -30px; */
//   margin-right: auto;
//   margin-left: auto;
//   padding: auto;

//   @media screen and (max-width: 960px) {
//     width: 120px;
//     margin-left: -15px;
//   }
// `
export const Logo = styled(Img)`
  width: 140px;
  /* margin-left: -30px; */
  margin-right: auto;
  margin-left: auto;
  padding: auto;

  @media screen and (max-width: 960px) {
    width: 120px;
    margin-left: -15px;
  }
`
