import styled, { createGlobalStyle } from "styled-components"
import "../node_modules/bootstrap-4-grid/css/grid.min.css"
import "../node_modules/bootstrap-4-grid/css/grid.css"

export const buttonBckg = {
  light: "rgba(85, 89, 257,1)",
  lightHover: "rgb(85, 82, 247)",
  dark: "rgba(41, 125, 245)",
  darkHover: "rgb(21, 113, 235)",
}

const fullContainer = {
  light: `radial-gradient(ellipse at bottom, ${buttonBckg.dark} 40%, #090a0f 100%)`,
  dark: `radial-gradient(ellipse at bottom, ${buttonBckg.light} 0%, #090a0f 40%)`,
}

const GlobalStyle = createGlobalStyle`

* {
  line-height:1.5;
  font-size:14px;
  letter-spacing: .6px;
    box-sizing:border-box;
    margin:0;
    padding:0;
    color:white;
    font-family:'Source Sans Pro',sans-serif;
    @keyframes animStar {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-300px);
  }
  100% {
    transform: translateY(0px);
  }
}

}

`

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  /* max-width: 2100px; */
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px;

  @media screen and (max-width: 2100px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`

export const FullContainer = styled.div`
  display: block;
  scroll-behavior: smooth;
  transition: all 400ms;

  position: relative;
  overflow: hidden;

  width: 100%;
  height: 95vh;

  margin-left: auto;
  margin-right: auto;
  background: ${({ primary }) =>
    primary === "light" ? fullContainer.light : fullContainer.dark};

  @media (max-width: 640px) {
    min-height: 600px;
  }

  @media (min-width: 641px) {
    min-height: 800px;
  }
`

export const Button = styled.button`
  display: inline-block;
  border-radius: 4px;
  background: ${({ dark }) => (dark ? buttonBckg.light : buttonBckg.dark)};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "8px 8px")};
  color: #fff;

  border: none;
  cursor: pointer;
  transition: all 300ms ease-out;

  &:hover {
    background: ${({ dark }) =>
      dark ? buttonBckg.lightHover : buttonBckg.darkHover};
    opacity: 0.95;
  }

  @media (max-width: 640px) {
    font-size: 1rem;
  }
`

export const H1 = styled.h1`
  text-transform: uppercase;

  @media (max-width: 640px) {
    font-size: 1.9rem;

    letter-spacing: 6px;

    ${({ landing }) =>
      landing &&
      ` font-size:2.1rem;  letter-spacing: 2px; text-transform: none;`};
    margin: auto auto auto auto;

    ${({ servicesPage }) => servicesPage && ` letter-spacing: 4px; `};
  }

  @media (min-width: 641px) {
    width: 90%;
    font-size: 2.55rem;
    ////
    //UJ
    font-size: 3.3rem;
    //UJ
    ///

    //
    //UJ
    width: 100%;
    //UJ
    //
    margin: auto auto auto auto;
    letter-spacing: 7px;
    ${({ landing }) =>
      landing &&
      `  text-transform: none; width:100%; font-size: 2.8rem;    letter-spacing: 3px;`};
  }
`
export const H2 = styled.h2`
  font-weight: 400;
  transition: all 500ms;
  display: inline-block;
  &:after {
    transition: all 300ms;
    margin: auto;
    margin-top: 0.5%;
    margin-bottom: 0.5%;
    border-radius: 5px;
    background: ${buttonBckg.light};
    content: "";
    display: block;
    height: 2px;
    width: 0%;
  }

  ${({ dark, values }) =>
    !dark &&
    values &&
    ` 
    
      &:after {
margin:auto;
        margin-top: 0.5%;
        margin-bottom: 0.5%;
        border-radius: 5px;
    background:${buttonBckg.light};
        content: "";
   display: block;
        height: 2px;
        width: 65%;
    // width:auto;

      }`}

  ${({ services, dark }) =>
    !dark &&
    services &&
    `&:after{
        background:${buttonBckg.dark};
        width:100%;
      }`}

  ${({ about }) =>
    about &&
    `
     &:after {
       margin-left:0;
// width:55%;
width:auto;


     }`}




  @media (max-width: 640px) {
    //UJ
    font-size: 1.2rem;
    //UJ
    ${({ about }) =>
      about &&
      `
    font-size: 1.2rem;`}
    ${({ service }) =>
      service &&
      `
      font-size:1.5rem`}
  }

  @media (min-width: 641px) {
    font-size: 1.15rem;
    //UJ
    font-size: 1.35rem;
    //UJ
    ${({ about }) =>
      about &&
      `
      font-size:1.3rem`}

    ${({ service }) =>
      service &&
      `
      font-size:1.9rem`}
  }
  @media (min-width: 1024px) {
    font-size: 1.3rem;
    //UJ
    font-size: 1.5rem;
    //UJ
    ${({ about }) =>
      about &&
      `
      font-size:1.6rem`}

    ${({ service }) =>
      service &&
      `
      font-size:2.1rem`}
          ${({ services }) =>
      services &&
      `
      font-size:2.1rem`}
  }
`
export const H3 = styled.h3`
  color: ${({ dark }) => (dark ? "#505050" : "white")};
  @media (max-width: 640px) {
    font-size: 1.05rem;
  }

  @media (min-width: 641px) {
    font-size: 1.2rem;
  }

  /* ${bold => (bold ? `font-weight: 500;` : `font-weight: 300;`)} */

  font-weight: ${({ bold }) => (bold === true ? 500 : 300)};
`

export const H4 = styled.h4`
  color: ${({ dark }) => (dark ? "#505050" : "white")};
  font-weight: 500;
  ${({ choose }) => choose && `font-weight:300;`}

  @media (max-width: 640px) {
    font-size: 0.9rem;
  }

  @media (min-width: 641px) {
    font-size: 1.1rem;
  }
`
export const H5 = styled.h5`
  font-weight: 500;

  @media (max-width: 640px) {
    font-size: 0.75rem;
  }

  @media (min-width: 641px) {
    font-size: 1rem;
  }

  ${({ footer, dark }) => footer && `color: ${dark ? "#505050" : "white"}`}
`
export const P = styled.p`
  font-weight: 300;
  color: #505050;

  @media (max-width: 640px) {
    font-size: 0.9rem;
  }

  @media (min-width: 641px) {
    font-size: 1.1rem;
  }
`

export const LandingButton = styled(Button)`
  padding: 9px;
  display: inline-block;
  pointer-events: all;
  width: fit-content;
  background-color: ${({ dark }) =>
    dark ? buttonBckg.light : buttonBckg.dark};
  border-radius: 20px;
  border-radius: 120px;
  transition: all 300ms;

  & > span {
    display: inline-block;
    transition: all 300ms;
    ${({ open }) =>
      open ? `transform: rotate(450deg)` : `transform: rotate(0deg)`}
  }

  &:hover {
    background-color: ${({ dark }) =>
      dark ? buttonBckg.light : buttonBckg.darkHover};
    transform: translateY(-3px);
    box-shadow: 0px 2px 10px 0px
      ${({ dark }) => (!dark ? buttonBckg.darkHover : buttonBckg.lightHover)};

    ${({ open }) =>
      !open &&
      `  & > span {
      display: inline-block;
      transform: translateX(3px);
    }`}
  }

  text-decoration: none !important;
  @media screen and (max-width: 960px) {
  }
  @media (max-width: 640px) {
    padding: 7px;
    width: auto;
    margin-top: 5%;
    font-size: 0.9rem !important;
  }

  @media (min-width: 641px) {
    margin-top: 5%;
  }

  @media (min-width: 1024px) {
    margin-top: 5%;
  }
`

export const OpenDetails = styled.div`
  width: 100%;
  transition: all 400ms;
  position: relative;
  ${({ open }) =>
    open
      ? `
   opacity:1;
   margin-top:0px;`
      : `
    opacity:0;

    @media (max-width: 640px) {
  margin-top:-280%;
    }

        @media (min-width: 641px) {
  margin-top:-300px;
    }


    pointer-events:none;
    `}
`
export const OpenDetailsSuccess = styled.div`
  width: 100%;
  transition: all 400ms;
  position: relative;

  ${({ open }) =>
    open
      ? `
   opacity:1;
   margin-top:0px;
  position: relative;
   `
      : `
    opacity:0;
  position: absolute;
    @media (max-width: 640px) {
  margin-top:-80%;

    }

        @media (min-width: 641px) {
  margin-top:-200px;
    }


    pointer-events:none;
    `}
`
export const Row = styled.div`
  max-width: 1540px;
  margin: auto auto auto auto;
`
export const StarsSix = styled.div`
  display: block;
  margin: auto;
  position: absolute;
  ${({ bottom }) => (bottom ? `bottom:50%;` : `top:0;`)}

  width: 1px;
  height: 1px;
  background: transparent;
  transform: rotate(360deg);
  box-shadow: 788px 1310px #fff, 456px 1074px #fff, 512px 1893px #fff,
    52px 1113px #fff, 244px 612px #fff, 731px 762px #fff, 337px 386px #fff,
    342px 622px #fff, 1949px 753px #fff, 990px 1892px #fff, 1371px 1299px #fff,
    1029px 784px #fff, 1769px 98px #fff, 1090px 1326px #fff, 1825px 1079px #fff,
    701px 658px #fff, 1233px 1002px #fff, 954px 730px #fff, 1675px 1539px #fff,
    631px 685px #fff, 1094px 510px #fff, 860px 376px #fff, 1693px 1039px #fff,
    11px 438px #fff, 777px 610px #fff, 1659px 1394px #fff, 1394px 630px #fff,
    277px 1341px #fff, 851px 473px #fff, 737px 794px #fff, 1423px 389px #fff,
    1314px 216px #fff, 5px 464px #fff, 1271px 1056px #fff, 1662px 223px #fff,
    245px 645px #fff, 819px 1428px #fff, 1919px 495px #fff, 1903px 1311px #fff,
    1254px 741px #fff, 1945px 224px #fff, 1313px 1015px #fff, 1787px 779px #fff,
    153px 121px #fff, 100px 643px #fff, 725px 51px #fff, 460px 1433px #fff,
    1254px 741px #fff, 1945px 224px #fff, 1313px 1015px #fff, 1787px 779px #fff,
    153px 121px #fff, 100px 643px #fff, 725px 51px #fff, 460px 1433px #fff,
    331px 650px #fff, 854px 1305px #fff, 1377px 634px #fff, 1459px 834px #fff,
    1750px 1253px #fff, 879px 821px #fff, 435px 1764px #fff, 256px 1658px #fff,
    904px 1446px #fff, 26px 231px #fff, 464px 340px #fff, 1211px 467px #fff,
    1742px 439px #fff, 1568px 1155px #fff, 8px 47px #fff, 1400px 1722px #fff,
    1878px 131px #fff, 1938px 429px #fff, 731px 133px #fff, 371px 1407px #fff,
    1456px 105px #fff, 431px 1963px #fff, 1762px 645px #fff, 1637px 1417px #fff,
    1208px 1992px #fff, 1010px 1008px #fff, 11px 740px #fff, 1401px 1932px #fff,
    1134px 765px #fff, 1590px 1093px #fff, 1903px 1991px #fff, 685px 302px #fff,
    1183px 1275px #fff, 473px 1117px #fff, 918px 472px #fff, 1952px 1363px #fff,
    1189px 1946px #fff, 225px 1509px #fff, 383px 917px #fff, 1461px 1931px #fff,
    258px 893px #fff, 192px 1091px #fff, 1549px 1724px #fff, 1254px 2px #fff,
    1492px 1741px #fff, 279px 350px #fff, 1074px 561px #fff, 1073px 1px #fff,
    4px 1595px #fff, 1338px 684px #fff, 52px 122px #fff, 495px 1361px #fff,
    1747px 1762px #fff, 1890px 944px #fff, 830px 1332px #fff, 618px 1193px #fff,
    878px 1557px #fff, 809px 1512px #fff, 115px 1552px #fff, 1393px 11px #fff,
    21px 1575px #fff, 1121px 1243px #fff, 1690px 113px #fff, 1263px 1903px #fff,
    404px 1388px #fff, 391px 2000px #fff, 405px 1017px #fff, 1453px 457px #fff,
    530px 1563px #fff, 1002px 734px #fff, 1439px 1705px #fff, 1797px 1092px #fff,
    1024px 537px #fff, 1119px 677px #fff, 1517px 67px #fff, 587px 191px #fff,
    1519px 1549px #fff, 83px 241px #fff, 516px 1855px #fff, 1954px 953px #fff,
    307px 1970px #fff, 551px 1268px #fff, 1890px 1569px #fff, 544px 767px #fff,
    1838px 309px #fff, 199px 63px #fff, 1267px 323px #fff, 1221px 8px #fff,
    753px 1660px #fff, 1576px 1577px #fff, 1138px 1667px #fff, 1878px 131px #fff,
    1938px 429px #fff, 731px 133px #fff, 371px 1407px #fff, 1456px 105px #fff,
    431px 1963px #fff, 1762px 645px #fff, 1637px 1417px #fff, 1837px 1451px #fff,
    560px 851px #fff, 1310px 783px #fff, 730px 1839px #fff, 203px 899px #fff,
    466px 507px #fff, 1868px 1654px #fff, 450px 724px #fff, 307px 1358px #fff,
    710px 894px #fff, 1069px 293px #fff, 487px 1252px #fff, 1967px 1617px #fff,
    316px 1143px #fff, 297px 292px #fff, 649px 466px #fff, 655px 1795px #fff,
    1368px 1610px #fff, 313px 694px #fff, 1470px 1135px #fff, 1244px 96px #fff,
    1749px 1791px #fff, 1290px 1657px #fff, 1816px 155px #fff, 361px 682px #fff,
    1230px 1132px #fff, 892px 143px #fff, 1779px 1079px #fff, 1392px 991px #fff,
    1305px 1241px #fff, 1250px 1969px #fff, 171px 484px #fff, 1364px 695px #fff,
    1021px 1865px #fff, 1375px 1956px #fff, 1170px 94px #fff, 1084px 14px #fff,
    1910px 1897px #fff, 1300px 1185px #fff, 366px 1322px #fff, 1767px 410px #fff,
    519px 1316px #fff, 1321px 1248px #fff, 1974px 1057px #fff, 1679px 245px #fff,
    1860px 1582px #fff, 259px 1959px #fff, 86px 1625px #fff, 1683px 1494px #fff,
    852px 266px #fff, 1297px 432px #fff, 771px 434px #fff, 1236px 641px #fff,
    951px 415px #fff, 1903px 698px #fff, 994px 1037px #fff, 1859px 48px #fff,
    452px 160px #fff, 421px 1569px #fff, 1953px 1327px #fff, 498px 1936px #fff,
    562px 256px #fff;
`

export default GlobalStyle
