import { Link } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { ThemeContext } from "../../contexts/ThemeContext"
import { buttonBckg, H5 } from "../../globalStyles"
import useToggleStateGetSet from "../../hooks/useToggleStateGetSet"
// import useToggleState from "../../hooks/useToggleState"
function Cookie({ cookieButton, cookieDataText, cookieText }) {
  const [isCookieCancelled, setCookie] = useToggleStateGetSet("Webmind-Cookie")

  const [open, setOpen] = useState(false)
  const { isDarkMode } = useContext(ThemeContext)

  useEffect(() => {
    let timeout
    if (isCookieCancelled)
      timeout = setTimeout(() => {
        setOpen(true)
      }, 1500)

    return () => {
      clearTimeout(timeout)
    }
  }, [isCookieCancelled])

  useEffect(() => {
    setOpen(false)
    return () => {}
  }, [isCookieCancelled])

  // console.log(isCookieCancelled)
  // if (!isCookieCancelled) return null
  return (
    <Row
      open={open}
      dark={isDarkMode}
      className="row justify-content-center mr-auto ml-auto text-center "
    >
      <ColMin className="col-12 col-md-8 col-lg-9 my-auto">
        <H5 dark={false}>{cookieText.cookieText}</H5>
      </ColMin>
      <ColMin className="col-6 col-md-2 col-lg-1 my-auto ">
        <GatsbyLink to="/privacy">{cookieDataText}</GatsbyLink>
      </ColMin>
      <ColMin className="col-6 col-md-2 col-lg-1 my-auto ">
        <Button cookie="true" onClick={setCookie} dark={isDarkMode}>
          {cookieButton}
        </Button>
      </ColMin>
    </Row>
  )
}

export default Cookie

export const Row = styled.div`
  width: 100%;
  transition: all 400ms;
  z-index: 10000;
  position: fixed;
  bottom: 0%;
  max-height: auto;
  background: ${({ dark }) => (!dark ? buttonBckg.light : buttonBckg.dark)};
  /* opacity: ${({ open }) => (open ? "1" : "0")}; */
  display: ${({ open }) => (open ? "flex" : "none")};
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.45);

  @media (max-width: 640px) {
  }

  @media (min-width: 641px) {
  }

  @media (min-width: 1024px) {
  }
`

export const ColMin = styled.div`
  height: auto;
  /* background: red; */

  @media (max-width: 640px) {
    padding: 2px 0 2px 0;
  }

  @media (min-width: 641px) {
    padding: 8px 0 8px 0;
  }

  @media (min-width: 1024px) {
  }
`

export const Button = styled.button`
  display: inline-block;
  border-radius: 4px;
  background: ${({ dark }) => (dark ? buttonBckg.light : buttonBckg.dark)};
  white-space: nowrap;
  padding: 7px 8px;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 300ms ease-out;

  &:hover {
    background: ${({ dark }) =>
      dark ? buttonBckg.lightHover : buttonBckg.darkHover};
    opacity: 0.95;
  }

  @media (max-width: 640px) {
    font-size: 0.75rem;
  }
`
export const GatsbyLink = styled(Link)`
  @media (max-width: 640px) {
    font-size: 0.75rem;
  }
`
