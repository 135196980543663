import { useState, useEffect } from "react"
// import saveToLocalStorage from "./saveToLocalStorage"

function useToggleStateGetSet(name) {
  let getItem = null
  if (typeof window !== `undefined`) {
    getItem = JSON.parse(window.localStorage.getItem(`${name}`))
  }

  getItem === null || undefined
    ? (getItem = true)
    : (getItem = JSON.parse(window.localStorage.getItem(`${name}`)))

  const [state, setState] = useState(getItem)

  const toggle = () => {
    setState(!state)
  }

  useEffect(() => {
    window.localStorage.setItem(`${name}`, JSON.stringify(state))
  }, [name, state])

  return [state, toggle]
}
export default useToggleStateGetSet

////DEFAULT
// import { useState, useEffect } from "react"
// // import saveToLocalStorage from "./saveToLocalStorage"

// function useToggleStateGetSet(name) {
//   if (typeof window !== `undefined`) {
//   }
//   let getItem = JSON.parse(window.localStorage.getItem(`${name}`))

//   getItem === null || undefined
//     ? (getItem = true)
//     : (getItem = JSON.parse(window.localStorage.getItem(`${name}`)))

//   // console.log(getItem)
//   const [state, setState] = useState(getItem)

//   const toggle = () => {
//     setState(!state)
//   }

//   useEffect(() => {
//     window.localStorage.setItem(`${name}`, JSON.stringify(state))
//   }, [toggle])

//   return [state, toggle]
// }
// export default useToggleStateGetSet

// import { useState, useEffect } from "react"
// // import saveToLocalStorage from "./saveToLocalStorage"

// function useToggleStateGetSet(name) {
//   let getItem = null
//   let windowGlobal
//   useEffect(() => {
//     windowGlobal = typeof window !== "undefined" && window
//     getItem = JSON.parse(windowGlobal.localStorage.getItem(`${name}`))
//     return () => {}
//   }, [])

//   getItem === null || undefined
//     ? (getItem = true)
//     : (getItem = JSON.parse(windowGlobal.localStorage.getItem(`${name}`)))

//   // console.log(getItem)
//   const [state, setState] = useState(getItem)

//   const toggle = () => {
//     setState(!state)
//   }

//   useEffect(() => {
//     windowGlobal.localStorage.setItem(`${name}`, JSON.stringify(state))
//   }, [toggle])

//   return [state, toggle]
// }
// export default useToggleStateGetSet
