// import ufo from "../../images/ufo.svg"
// KITOROLNI
// import { BrowserRouter } from "react-router-dom"
// import { ScrollContext } from "gatsby-react-router-scroll"
// KITOROLNI
import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import { ThemeContextProvider } from "../contexts/ThemeContext"
import GlobalStyle from "../globalStyles"
import "../styles/layout.css"
import Cookie from "./Cookie/Cookie"
import NavbarComponent from "./Navbar/Navbar"

const Layout = ({ children }) => {
  // const location = useLocation()
  // const headerRef = useRef(null)
  // const [width] = useWindowSize()
  const [isCookie, setCookie] = useState(true)

  const data = useStaticQuery(graphql`
    {
      allContentfulCookieLayoutMouse(filter: { node_locale: { eq: "hu" } }) {
        nodes {
          cookieButton
          cookieDataText
          cookieText {
            cookieText
          }
          svg {
            file {
              url
            }
          }
        }
      }
    }
  `)
  const {
    cookieButton,
    cookieDataText,
    cookieText,
    // svg,
  } = data.allContentfulCookieLayoutMouse.nodes[0]

  // useEffect(() => {
  //   location.pathname !== "/" && gsapBall(headerRef.current)
  //   // console.log(headerRef)
  //   return () => {}
  // }, [width, location.pathname])

  useEffect(() => {
    setCookie(JSON.parse(window.localStorage.getItem("Webmind-Cookie")))
    // console.log(isCookie)
    return () => {}
  }, [isCookie])

  return (
    <div className="main">
      <ThemeContextProvider>
        <ParallaxProvider>
          {/* <LanguageProvider> */}
          <GlobalStyle />
          <NavbarComponent />
          <div className="children">
            {children}
            {/* CURSOR UFO */}
            {/* {location.pathname !== "/" && width > 1023 && (
              <Ball src={svg.file.url} ref={headerRef} className="ball" />
            )} */}
            {/* CURSOR UFO */}
          </div>
          {isCookie && (
            <Cookie
              cookieButton={cookieButton}
              cookieDataText={cookieDataText}
              cookieText={cookieText}
            />
          )}
          {/* </LanguageProvider> */}
        </ParallaxProvider>
      </ThemeContextProvider>
    </div>
  )
}

export default Layout

// const Ball = styled.img`
//   width: 35px;
//   height: 35px;
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 99999;
//   pointer-events: none;
//   opacity: 0.9;
// `
